import { Button, Callout, Tooltip } from '@blueprintjs/core'
import { QueryParams } from '@curri/utils'
import { Spacer } from 'components/Spacer'
import Router from 'next/router'
import { signIn } from 'next-auth/react'
import React, { useState } from 'react'
import { Img } from 'react-image'
import styled from 'styled-components'
import { baseUrl } from 'utils/helpers'

const Wrapper = styled.div`
  width: 400px;
  margin: auto;
`

function Login({ refetchUser }: { refetchUser: Function }) {
  // Check for Okta response and set JWT if there is one
  const jwt = QueryParams.getParameterByName('jwt')
  if (jwt) {
    window.localStorage.setItem('jwt', jwt)
    Router.push('/deliveries')
    refetchUser()
  }

  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(
    !!window.localStorage.getItem('jwt')
  )

  const error = QueryParams.getParameterByName('error')

  return (
    <Wrapper>
      {baseUrl.logos && (
        <Img
          width="100%"
          src={[
            `${baseUrl.logos}/common-canvas/transparent/curri.com.png`,
            `${baseUrl.logos}/common-canvas/transparent/404@2x.png`,
          ]}
        />
      )}

      <Tooltip fill content={isLoggingIn ? 'Logging in...' : ''}>
        <Button
          loading={isLoggingIn}
          onClick={() => {
            setIsLoggingIn(true)
            signIn('okta-curri')
          }}
          icon={
            <img
              height={30}
              width={30}
              src={require('../../public/okta.png')}
              color="grey"
            />
          }
          large
        >
          <span style={{ fontSize: '16px', fontWeight: 'bolder' }}>
            Continue with Okta
          </span>
        </Button>
      </Tooltip>

      <Spacer value={10} />

      {error && (
        <Callout
          style={{ borderRadius: '10px', textAlign: 'center' }}
          intent="danger"
          icon="error"
          title={error}
        />
      )}
    </Wrapper>
  )
}

export default Login
